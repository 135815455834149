
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
  setCurrentPageToolbarType
} from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useRoute, useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import { emitter } from "@/main";

export default defineComponent({
  name: "work-order-details",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  data: function() {
    let workOrderID: any;
    const dataLoaded = false;
    const woData = {
      title: "",
      number: "",
      description: "",
      type: "",
      project: "",
      assignedTo: "",
      deadline: "",
      created: "",
      assets: "",
      procedures: "",
      checklists: "",
      chkPdf: [],
      media: []
    };
    return {
      woData,
      dataLoaded,
      workOrderID
    };
  },
  created: function() {
    const router = useRouter();
    const route = useRoute();
    this.workOrderID = route.params.workOrderID;
    setTimeout(() => {
      this.loadWorkOrderDetails();
    }, 100);
  },
  methods: {
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    loadWorkOrderDetails() {
      ApiService.setHeader();
      ApiService.get(
        "https://cmms.onecorp.cloud/api/v2/work-orders/view/" + this.workOrderID
      )
        .then(data => {
          this.$nextTick(() => {
            this.woData = data.data.data;
            //console.log(this.woData);
            this.dataLoaded = true;
          });
        })
        .catch(response => {
          console.log(response);
        });
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Work order details");
      setCurrentPageBreadcrumbs("Work order details", ["Work orders"]);
      setCurrentPageToolbarType("workOrdersDetails");
    });

    return {};
  }
});
