<template>
  <div class="createWorkOrderGrid" :class="[!dataLoaded && 'overlay overlay-block']">
    <div
        class="overlay-layer rounded bg-white bg-opacity-5"
        :class="[dataLoaded && 'd-none']"
    >
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div :class="{'row': dataLoaded, 'd-none': !dataLoaded }" v-if="dataLoaded">
      <div class="col-4">
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10 accordion-header">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer accordion-button"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_wo_general_info"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <div class="d-flex">
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                  src="media/icons/duotone/Text/Align-left.svg"
                  class="w-30px me-6"
                />
              </span>
              <div class="d-flex flex-column">
                <h3 class="fw-bolder m-0 pt-1">General information</h3>
              </div>
            </div>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_wo_general_info" class="collapse show">
          <!--begin::Form-->
          <Form
            id="kt_wo_general_info_form"
            class="form"
            novalidate="novalidate"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >Title</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="woName"
                    readonly
                    v-model="woData.title"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="WO name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="woName" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >Number</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    v-model="woData.number"
                    name="woNumber"
                    readonly
                    class="form-control form-control-lg form-control-solid"
                    placeholder="WO number"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="woNumber" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="d-flex flex-column mb-8">
                <label class="fs-6 fw-bold mb-2"
                  ><span class="required">Description</span></label
                >
                <el-input
                  type="textarea"
                  rows="3"
                  v-model="woData.description"
                  readonly
                  name="woDescription"
                  placeholder="Add WO description"
                />
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10 accordion-header">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer accordion-button"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_wo_type"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <div class="d-flex">
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                  src="media/icons/duotone/General/Settings-1.svg"
                  class="w-30px me-6"
                />
              </span>
              <div class="d-flex flex-column">
                <h3 class="fw-bolder m-0 pt-1">Type</h3>
              </div>
            </div>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_wo_type" class="collapse show">
          <!--begin::Form-->
          <Form id="kt_wo_type_form" class="form" novalidate="novalidate">
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="fv-row">
                <!--begin:Options-->
                <div class="fv-row pb-2">
                  <!--begin:Option-->
                  <label class="d-flex flex-stack mb-5 cursor-pointer">
                    <!--begin:Label-->
                    <span class="d-flex align-items-center me-2">
                      <!--begin:Icon-->
                      <span class="symbol symbol-50px me-6">
                        <span class="symbol-label bg-light-primary">
                          <span class="svg-icon svg-icon-1 svg-icon-primary">
                            <inline-svg
                              src="media/icons/duotone/Tools/Pantone.svg"
                            />
                          </span>
                        </span>
                      </span>
                      <!--end:Icon-->

                      <!--begin:Info-->
                      <span class="d-flex flex-column">
                        <span class="fw-bolder fs-6"
                          >General Work Order
                          <el-tooltip
                            content="A general work order is any maintenance task that isn’t considered a preventive maintenance, inspection, emergency, or corrective maintenance task. General work orders may include tasks like setting up new equipment, dismantling old equipment or painting walls, for example."
                            placement="left"
                            popper-class="mw-300px"
                          >
                            <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                          </el-tooltip>
                        </span>
                      </span>
                      <!--end:Info-->
                    </span>
                    <!--end:Label-->

                    <!--begin:Input-->
                    <span class="form-check form-check-custom form-check-solid">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="workOrderType"
                        v-model="woData.type"
                        value="50230"
                      />
                    </span>
                    <!--end:Input-->
                  </label>
                  <!--end::Option-->

                  <!--begin:Option-->
                  <label class="d-flex flex-stack mb-5 cursor-pointer">
                    <!--begin:Label-->
                    <span class="d-flex align-items-center me-2">
                      <!--begin:Icon-->
                      <span class="symbol symbol-50px me-6">
                        <span class="symbol-label bg-light-success ">
                          <span class="svg-icon svg-icon-1 svg-icon-success">
                            <inline-svg
                              src="media/icons/duotone/General/Binocular.svg"
                            />
                          </span>
                        </span>
                      </span>
                      <!--end:Icon-->

                      <!--begin:Info-->
                      <span class="d-flex flex-column">
                        <span class="fw-bolder fs-6"
                          >Preventive Maintenance
                          <el-tooltip
                            content="A preventive maintenance work order is a scheduled routine maintenance task performed on individual assets or groups of assets. Preventive maintenance keeps equipment in its existing state and prevents deterioration or failure. Preventive work orders define resource requirements, instructions, checklists and notes for each task."
                            placement="left"
                            popper-class="mw-300px"
                          >
                            <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                          </el-tooltip>
                        </span>
                      </span>
                      <!--end:Info-->
                    </span>
                    <!--end:Label-->

                    <!--begin:Input-->
                    <span class="form-check form-check-custom form-check-solid">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="workOrderType"
                        v-model="woData.type"
                        value="50231"
                      />
                    </span>
                    <!--end:Input-->
                  </label>
                  <!--end::Option-->

                  <!--begin:Option-->
                  <label class="d-flex flex-stack mb-5 cursor-pointer">
                    <!--begin:Label-->
                    <span class="d-flex align-items-center me-2">
                      <!--begin:Icon-->
                      <span class="symbol symbol-50px me-6">
                        <span class="symbol-label bg-light-info">
                          <span class="svg-icon svg-icon-1 svg-icon-info">
                            <inline-svg
                              src="media/icons/duotone/General/Search.svg"
                            />
                          </span>
                        </span>
                      </span>
                      <!--end:Icon-->

                      <!--begin:Info-->
                      <span class="d-flex flex-column">
                        <span class="fw-bolder fs-6"
                          >Inspection
                          <el-tooltip
                            content="An inspection work order requires a maintenance technician to audit or inspect an asset based on a predetermined set of parameters. Inspections are usually time-based. During an inspection, a maintenance technician may identify a problem and then create a new work order to correct that problem."
                            placement="left"
                            popper-class="mw-300px"
                          >
                            <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                          </el-tooltip>
                        </span>
                      </span>
                      <!--end:Info-->
                    </span>
                    <!--end:Label-->

                    <!--begin:Input-->
                    <span class="form-check form-check-custom form-check-solid">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="workOrderType"
                        v-model="woData.type"
                        value="50232"
                      />
                    </span>
                    <!--end:Input-->
                  </label>
                  <!--end::Option-->
                  <!--begin:Option-->
                  <label class="d-flex flex-stack mb-5 cursor-pointer">
                    <!--begin:Label-->
                    <span class="d-flex align-items-center me-2">
                      <!--begin:Icon-->
                      <span class="symbol symbol-50px me-6">
                        <span class="symbol-label bg-light-danger">
                          <span class="svg-icon svg-icon-1 svg-icon-danger">
                            <inline-svg
                              src="media/icons/duotone/General/Fire.svg"
                            />
                          </span>
                        </span>
                      </span>
                      <!--end:Icon-->

                      <!--begin:Info-->
                      <span class="d-flex flex-column">
                        <span class="fw-bolder fs-6"
                          >Emergency
                          <el-tooltip
                            content="An emergency work order is generated when an unscheduled breakdown occurs and an asset needs to be repaired right away. An emergency work order is used to record and track any reactive work performed that was not planned beforehand. Once the work is complete, the maintenance technician can provide information in the work order about what happened, why the breakdown occurred, what was done about it, and what can be done to prevent it from occurring again."
                            placement="left"
                            popper-class="mw-300px"
                          >
                            <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                          </el-tooltip>
                        </span>
                      </span>
                      <!--end:Info-->
                    </span>
                    <!--end:Label-->

                    <!--begin:Input-->
                    <span class="form-check form-check-custom form-check-solid">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="workOrderType"
                        v-model="woData.type"
                        value="50233"
                      />
                    </span>
                    <!--end:Input-->
                  </label>
                  <!--end::Option-->
                  <!--begin:Option-->
                  <label class="d-flex flex-stack cursor-pointer">
                    <!--begin:Label-->
                    <span class="d-flex align-items-center me-2">
                      <!--begin:Icon-->
                      <span class="symbol symbol-50px me-6">
                        <span class="symbol-label bg-light-warning">
                          <span class="svg-icon svg-icon-1 svg-icon-warning">
                            <inline-svg
                              src="media/icons/duotone/Tools/Tools.svg"
                            />
                          </span>
                        </span>
                      </span>
                      <!--end:Icon-->

                      <!--begin:Info-->
                      <span class="d-flex flex-column">
                        <span class="fw-bolder fs-6"
                          >Corrective Maintenance
                          <el-tooltip
                            content="A corrective maintenance work order is generated when a maintenance technician discovers a problem while completing a preventive maintenance, inspection, general or emergency work order. Corrective maintenance is performed to identify, isolate and rectify a fault so that the equipment, machine or system can be restored to its original condition. Unlike an emergency work order, a corrective maintenance work order is planned and scheduled because the failure was identified in time. A corrective maintenance work order may consist of the repair, restoration or replacement of equipment."
                            placement="left"
                            popper-class="mw-300px"
                          >
                            <i class="fas fa-exclamation-circle ms-2 fs-7"></i>
                          </el-tooltip>
                        </span>
                      </span>
                      <!--end:Info-->
                    </span>
                    <!--end:Label-->

                    <!--begin:Input-->
                    <span class="form-check form-check-custom form-check-solid">
                      <Field
                        class="form-check-input"
                        type="radio"
                        name="workOrderType"
                        v-model="woData.type"
                        value="50234"
                      />
                    </span>
                    <!--end:Input-->
                  </label>
                  <!--end::Option-->
                  <ErrorMessage
                    class="fv-plugins-message-container invalid-feedback"
                    name="workOrderType"
                  />
                </div>
                <!--end:Options-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
      </div>
      <div class="col-4">
        <!--begin::Basic info-->
        <div class="card mb-5 mb-xl-10 accordion-header">
          <!--begin::Card header-->
          <div
            class="card-header border-0 cursor-pointer accordion-button"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_wo_project"
          >
            <!--begin::Card title-->
            <div class="card-title m-0">
              <div class="d-flex">
                <span class="svg-icon svg-icon-1 svg-icon-primary">
                  <inline-svg
                    src="media/icons/duotone/Files/Group-folders.svg"
                    class="w-30px me-6"
                  />
                </span>
                <div class="d-flex flex-column">
                  <h3 class="fw-bolder m-0 pt-1">Project</h3>
                </div>
              </div>
            </div>
            <!--end::Card title-->
          </div>
          <!--begin::Card header-->

          <!--begin::Content-->
          <div id="kt_wo_project" class="collapse show">
            <!--begin::Form-->
            <Form id="kt_wo_project_form" class="form" novalidate="novalidate">
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <div class="d-flex flex-column mb-8 fv-row">
                  <el-input
                    type="text"
                    v-model="woData.project"
                    readonly
                    name="woProject"
                    placeholder="Choose project for WO"
                  />
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Card body-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Basic info-->
        <!--begin::Basic info-->
        <div class="card mb-5 mb-xl-10 accordion-header">
          <!--begin::Card header-->
          <div
            class="card-header border-0 cursor-pointer accordion-button"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_wo_assigned_to"
          >
            <!--begin::Card title-->
            <div class="card-title m-0">
              <div class="d-flex">
                <span class="svg-icon svg-icon-1 svg-icon-primary">
                  <inline-svg
                    src="media/icons/duotone/Communication/Group.svg"
                    class="w-30px me-6"
                  />
                </span>
                <div class="d-flex flex-column">
                  <h3 class="fw-bolder m-0 pt-1">Assigned to</h3>
                </div>
              </div>
            </div>
            <!--end::Card title-->
          </div>
          <!--begin::Card header-->

          <!--begin::Content-->
          <div id="kt_wo_assigned_to" class="collapse show">
            <!--begin::Form-->
            <Form
              id="kt_wo_assigned_to_form"
              class="form"
              novalidate="novalidate"
            >
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <div class="d-flex flex-column mb-8 fv-row">
                  <el-input
                    class="w-100"
                    readonly
                    v-model="woData.assignedTo"
                    placeholder="Choose employees for WO"
                    name="employees"
                  />
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Card body-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Basic info-->
        <!--begin::Basic info-->
        <div class="card mb-5 mb-xl-10 accordion-header">
          <!--begin::Card header-->
          <div
            class="card-header border-0 cursor-pointer accordion-button"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_wo_timing"
          >
            <!--begin::Card title-->
            <div class="card-title m-0">
              <div class="d-flex">
                <span class="svg-icon svg-icon-1 svg-icon-primary">
                  <inline-svg
                    src="media/icons/duotone/Code/Time-schedule.svg"
                    class="w-30px me-6"
                  />
                </span>
                <div class="d-flex flex-column">
                  <h3 class="fw-bolder m-0 pt-1">Deadline</h3>
                </div>
              </div>
            </div>
            <!--end::Card title-->
          </div>
          <!--begin::Card header-->

          <!--begin::Content-->
          <div id="kt_wo_timing" class="collapse show">
            <!--begin::Form-->
            <Form id="kt_wo_timing_form" class="form" novalidate="novalidate">
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <div class="d-flex flex-column mb-8 fv-row">
                  <el-input
                    class="w-100"
                    v-model="woData.deadline"
                    readonly
                    placeholder="Select a date"
                    name="deadline"
                  />
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Card body-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Basic info-->
        <!--begin::Basic info-->
        <div class="card mb-5 mb-xl-10 accordion-header">
          <!--begin::Card header-->
          <div
            class="card-header border-0 cursor-pointer accordion-button"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_wo_created"
          >
            <!--begin::Card title-->
            <div class="card-title m-0">
              <div class="d-flex">
                <span class="svg-icon svg-icon-1 svg-icon-primary">
                  <inline-svg
                    src="media/icons/duotone/Code/Time-schedule.svg"
                    class="w-30px me-6"
                  />
                </span>
                <div class="d-flex flex-column">
                  <h3 class="fw-bolder m-0 pt-1">Created (temp)</h3>
                </div>
              </div>
            </div>
            <!--end::Card title-->
          </div>
          <!--begin::Card header-->

          <!--begin::Content-->
          <div id="kt_wo_created" class="collapse show">
            <!--begin::Form-->
            <Form id="kt_wo_created_form" class="form" novalidate="novalidate">
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <div class="d-flex flex-column mb-8 fv-row">
                  <el-input
                    class="w-100"
                    readonly
                    v-model="woData.created"
                    placeholder="Select a date"
                    name="createdDate"
                  />
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Card body-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Basic info-->
      </div>
      <div class="col-4">
        <!--begin::Basic info-->
        <div class="card mb-5 mb-xl-10 accordion-header">
          <!--begin::Card header-->
          <div
            class="card-header border-0 cursor-pointer accordion-button"
            :class="{'collapsed': woData.assets.length == 0}"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_wo_asset_ref"
          >
            <!--begin::Card title-->
            <div class="card-title m-0">
              <div class="d-flex">
                <span class="svg-icon svg-icon-1 svg-icon-primary">
                  <inline-svg
                    src="media/icons/duotone/Devices/Server.svg"
                    class="w-30px me-6"
                  />
                </span>
                <div class="d-flex flex-column">
                  <h3 class="fw-bolder m-0 pt-1">Assets</h3>
                </div>
              </div>
            </div>
            <!--end::Card title-->
          </div>
          <!--begin::Card header-->

          <!--begin::Content-->
          <div id="kt_wo_asset_ref" class="collapse" :class="{'hide': woData.assets.length == 0, 'show': woData.assets.length > 0}">
            <!--begin::Form-->
            <Form id="kt_wo_asset_ref_form" class="form" novalidate="novalidate">
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <div class="d-flex flex-column mb-8 fv-row">
                  <el-input
                    class="w-100"
                    readonly
                    v-model="woData.assets"
                    placeholder="Choose assets for WO"
                    name="assets"
                  />
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Card body-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Basic info-->
        <!--begin::Basic info-->
        <div class="card mb-5 mb-xl-10 accordion-header">
          <!--begin::Card header-->
          <div
            class="card-header border-0 cursor-pointer accordion-button"
            :class="{'collapsed': woData.procedures.length == 0}"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_wo_procedure_ref"
          >
            <!--begin::Card title-->
            <div class="card-title m-0">
              <div class="d-flex">
                <span class="svg-icon svg-icon-1 svg-icon-primary">
                  <inline-svg
                    src="media/icons/duotone/Text/Align-justify.svg"
                    class="w-30px me-6"
                  />
                </span>
                <div class="d-flex flex-column">
                  <h3 class="fw-bolder m-0 pt-1">Procedure</h3>
                </div>
              </div>
            </div>
            <!--end::Card title-->
          </div>
          <!--begin::Card header-->

          <!--begin::Content-->
          <div id="kt_wo_procedure_ref" class="collapse" :class="{'hide': woData.procedures.length == 0, 'show': woData.procedures.length > 0}">
            <!--begin::Form-->
            <Form
              id="kt_wo_procedure_ref_form"
              class="form"
              novalidate="novalidate"
            >
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <div class="d-flex flex-column mb-8 fv-row">
                  <el-input
                    class="w-100"
                    readonly
                    v-model="woData.procedures"
                    placeholder="Choose procedures for WO"
                    name="procedures"
                  />
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Card body-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Basic info-->
        <!--begin::Basic info-->
        <div class="card mb-5 mb-xl-10 accordion-header">
          <!--begin::Card header-->
          <div
            class="card-header border-0 cursor-pointer accordion-button"
            :class="{'collapsed': woData.checklists.length == 0}"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_wo_checklist_ref"
          >
            <!--begin::Card title-->
            <div class="card-title m-0">
              <div class="d-flex">
                <span class="svg-icon svg-icon-1 svg-icon-primary">
                  <inline-svg
                    src="media/icons/duotone/Text/Bullet-list.svg"
                    class="w-30px me-6"
                  />
                </span>
                <div class="d-flex flex-column">
                  <h3 class="fw-bolder m-0 pt-1">Checklists</h3>
                </div>
              </div>
            </div>
            <!--end::Card title-->
          </div>
          <!--begin::Card header-->

          <!--begin::Content-->
          <div id="kt_wo_checklist_ref" class="collapse" :class="{'hide': woData.checklists.length == 0 && woData.chkPdf.length == 0, 'show': woData.checklists.length > 0 || woData.chkPdf.length > 0}">
            <!--begin::Form-->
            <Form
              id="kt_wo_checklist_ref_form"
              class="form"
              novalidate="novalidate"
            >
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <div class="d-flex flex-column mb-8 fv-row">
                  <el-input
                    class="w-100"
                    readonly
                    v-model="woData.checklists"
                    placeholder="Choose checklists for WO"
                    name="checklists"
                  />
                </div>
                <!--end::Input group-->


                <template v-for="(chkPdf, index) in woData.chkPdf" :key="index">
                  <div class="mb-7 d-flex align-items-center">
                    <div class="symbol symbol-50px me-5">
                      <span class="bg-light-danger symbol-label">
                        <span class="svg-icon-danger svg-icon svg-icon-2x">
                          <inline-svg src="media/icons/duotone/Files/Compiled-file.svg" />
                        </span>
                      </span>
                    </div>
                    <div class="d-flex flex-column">
                      <a :href="`${chkPdf.link}`" target="_blank" class="text-dark text-hover-primary fs-6 fw-bolder">{{chkPdf.name.split(".")[0].replaceAll("_", " ")}}</a>
                      <span class="text-muted fw-bold">{{chkPdf.name.split(".")[1].toUpperCase()}}</span>
                    </div>
                  </div>
                </template>
              </div>
              <!--end::Card body-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Basic info-->
        <!--begin::Basic info-->
        <div class="card mb-5 mb-xl-10 accordion-header">
          <!--begin::Card header-->
          <div
            class="card-header border-0 cursor-pointer accordion-button"
            :class="{'collapsed': woData.media.length == 0}"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_wo_media_files"
          >
            <!--begin::Card title-->
            <div class="card-title m-0">
              <div class="d-flex">
                <span class="svg-icon svg-icon-1 svg-icon-primary">
                  <inline-svg
                    src="media/icons/duotone/Files/Pictures1.svg"
                    class="w-30px me-6"
                  />
                </span>
                <div class="d-flex flex-column">
                  <h3 class="fw-bolder m-0 pt-1">Media files</h3>
                </div>
              </div>
            </div>
            <!--end::Card title-->
          </div>
          <!--begin::Card header-->

          <!--begin::Content-->
          <div id="kt_wo_media_files" class="collapse" :class="{'hide': woData.media.length == 0, 'show': woData.media.length > 0}">
            <!--begin::Form-->
            <Form
              id="kt_wo_media_files_form"
              class="form"
              novalidate="novalidate"
            >
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <div class="row mb-6">
                  <div v-viewer='{url: "data-src"}' class="images">
                    <template v-for="(photo,index) in woData.media" :key="index">
                      <div class="col-2 mb-5 galleryPhotoThumb symbol symbol-60px symbol-lg-70px symbol-fixed">
                        <img
                            :src="photo.sm" class="image"
                            :data-src="photo.md"
                            :alt="woData.title"
                        >
                      </div>
                    </template>
                  </div>
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Card body-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Basic info-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
  setCurrentPageToolbarType
} from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useRoute, useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import { emitter } from "@/main";

export default defineComponent({
  name: "work-order-details",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  data: function() {
    let workOrderID: any;
    const dataLoaded = false;
    const woData = {
      title: "",
      number: "",
      description: "",
      type: "",
      project: "",
      assignedTo: "",
      deadline: "",
      created: "",
      assets: "",
      procedures: "",
      checklists: "",
      chkPdf: [],
      media: []
    };
    return {
      woData,
      dataLoaded,
      workOrderID
    };
  },
  created: function() {
    const router = useRouter();
    const route = useRoute();
    this.workOrderID = route.params.workOrderID;
    setTimeout(() => {
      this.loadWorkOrderDetails();
    }, 100);
  },
  methods: {
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    loadWorkOrderDetails() {
      ApiService.setHeader();
      ApiService.get(
        "https://cmms.onecorp.cloud/api/v2/work-orders/view/" + this.workOrderID
      )
        .then(data => {
          this.$nextTick(() => {
            this.woData = data.data.data;
            //console.log(this.woData);
            this.dataLoaded = true;
          });
        })
        .catch(response => {
          console.log(response);
        });
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Work order details");
      setCurrentPageBreadcrumbs("Work order details", ["Work orders"]);
      setCurrentPageToolbarType("workOrdersDetails");
    });

    return {};
  }
});
</script>
